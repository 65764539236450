import React, { useState } from 'react';
import { navigate } from 'gatsby'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import Link from 'i18n/Link'
import Flex from 'components/Flex'
import Box from 'components/Box'
import Input from 'components/Input'
import Button from 'components/Button'

const PageSwitcher = ({ partNum, totalParts, locale, totalPage, layoutId, ...props }) => {
  const [num, setNum] = useState(partNum);

  const handleEnter = ({ keyCode }) => {
    if (num && keyCode === 13) navigate(`/${locale}/layouts/${layoutId}/part/${num}`)
  }

  return (
    <Flex
      position="fixed"
      width={1}
      {...props}
    >
      <Button.icon hoverColor="primary" is={Link} to={`/layouts/${layoutId}/part/${partNum - 1}`} disabled={partNum === '1'}>
        <MdChevronLeft size={28} />
      </Button.icon>
      <Box flex="1" textAlign="center">
        <Input
          display="inline-block"
          value={num}
          textAlign="center"
          width="4em"
          mr="0.5em"
          type="number"
          min="1"
          max={totalParts}
          onKeyUp={handleEnter}
          onChange={(e) => {
            setNum(e.target.value)
          }}
          onClick={(e) => e.target.select()}
        />
        / {totalParts} (total {totalPage} pages)
      </Box>
      <Button.icon hoverColor="primary" is={Link} to={`/layouts/${layoutId}/part/${+partNum + 1}`} disabled={+partNum === totalParts}>
        <MdChevronRight size={28} />
      </Button.icon>
    </Flex>
  );
};

export default PageSwitcher;
