import React, { createElement } from 'react';
import isNumber from 'lodash/isNumber';
import mapValues from 'lodash/mapValues';

import Box from 'components/Box'

import getElement from './getElement'
import LayoutContext from './LayoutContext'

const CoverPage = ({ template, pageId, columnPoses }) => {
  // console.log(template.modules)
  // console.log(columnPoses)
  return (
    <Box position="absolute" overflow="hidden" top="0" left="0" right="0" bottom="0">
      <LayoutContext.Consumer>
        {({ contents }) => template.modules.map(({
          element,
          name,
          props,
          mapChildToProp,
          position,
        }) => {
          const id = `${pageId}/${name}`;
          const children = contents[id];
          return createElement(
            getElement(element),
            Object.assign(
              { key: name, id },
              props,
              position ? {
                position: 'absolute',
                ...mapValues(position, (val) => {
                  if (isNumber(val)) return `${val}mm`;
                  const res = /^\$(\d)$/.exec(val)
                  if (res) return `${columnPoses[res[1] - 1]}mm`
                  return val;
                }),
              } : {},
              mapChildToProp ? { [mapChildToProp]: children }: {}
            ),
            !mapChildToProp && children
          );
        })}
      </LayoutContext.Consumer>
    </Box>
  );
};

export default CoverPage;
