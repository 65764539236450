import React from 'react'
import toPairs from 'lodash/toPairs';

import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Button from 'components/Button'

import Link from 'i18n/Link'

import { selectFirebaseData } from 'services/firebase/selectors';

const IndexPage = ({ layouts }) => (
  <Flex alignItems="center">
    {layouts && toPairs(layouts).map(([key, { name }]) => (
      <Box key={key} width={1 / 4} p="2em">
        <Box border="2px solid" borderColor="primary" py="3em" textAlign="center">
          <Text.h3 color="primary">{name}</Text.h3>
          <Button mt="1em" is={Link} to={`/layouts/${key}`}>Go</Button>
        </Box>
      </Box>
    ))}
  </Flex>
)

export default selectFirebaseData(['layouts'])(IndexPage)
