import React, { PureComponent, Component } from 'react';
// import { withContentRect } from 'react-measure'
import md5 from 'blueimp-md5'
import isEqual from 'lodash/isEqual'

import Loading from 'components/Loading'
// import Box from 'components/Box'
import BackgroundImage from 'components/BackgroundImage'

import { selectFirebaseData } from 'services/firebase/selectors'
import LayoutContext from '../LayoutPage/LayoutContext'

class Viewer extends Component {
  static getDerivedStateFromProps(props, { naturalWidth, naturalHeight }) {
    const cropping = props[`${props.hash}Cropping`]
    if (!naturalWidth || !naturalHeight) return {}
    if (!cropping || !cropping.crop) {
      return {
        ratio: naturalHeight / naturalWidth,
      }
    }
    const { crop } = cropping
    const { x, y, width, height } = crop
    const scale = [100 / width, 100 / height]
    return {
      crop,
      ratio: (height * naturalHeight) / (width * naturalWidth),
      scale: scale.map(s => `${s * 100}%`).join(' '),
      position: `${x * 100 / (100 - width)}% ${y * 100 / (100 - height)}%`
    }
  }

  state = {}

  componentDidMount() {
    const img = new Image()
    img.onload = () => {
      this.setState({
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight,
      })
    }
    img.src = this.props.src
  }

  shouldComponentUpdate(nextProps, nextState) {
    const cropping = `${this.props.hash}Cropping`
    return ['naturalHeight', 'naturalWidth'].some((key) => this.state[key] !== nextState[key])
      || !isEqual(this.props[cropping], nextProps[cropping])
  }

  render() {
    const { src } = this.props
    const { naturalWidth, naturalHeight, ratio, scale, position } = this.state
    if (!src) return null
    if (!naturalWidth || !naturalHeight) return <Loading />
    return (
      <BackgroundImage
        ratio={ratio}
        src={src}
        backgroundSize={scale}
        backgroundPosition={position}
      >
        {/* <Box position="absolute" width="1px" height="100%" left="50%" top="0" bg="black" />
        <Box position="absolute" width="100%" height="1px" left="0" top="50%" bg="black" /> */}
      </BackgroundImage>
    );
  }
}

class ContextViewer extends PureComponent {
  static contextType = LayoutContext

  hash = md5(this.props.id)

  EnhancedViewer = selectFirebaseData([
    {
      path: `cropping/${this.context.layoutId}/${this.props.id}`,
      storeAs: `${this.hash}Cropping`,
    }
  ])(Viewer)

  render() {
    const { EnhancedViewer } = this
    return <EnhancedViewer key={this.props.src} {...this.props} hash={this.hash} />
  }
}

export default ContextViewer
