import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import mime from 'mime-types';
import shortid from 'shortid';
import { withFirebase } from 'react-redux-firebase';
import md5 from 'blueimp-md5'

const STORAGE_PATH = 'uploads';
const URL_PATH = 'downloadUrls';

// const isBase64 = str => {
//   const regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
//   return regex.test(str);
// };

class BindStorage extends PureComponent {
  static propTypes = {
    firebase: PropTypes.object.isRequired,
  };

  getFileRef = filename => {
    const { firebase } = this.props;
    const storageRef = firebase.storage().ref();
    return storageRef.child(filename);
  };

  handleFileUpload = file => {
    const filename = `${shortid.generate()}.${mime.extension(file.type)}`;
    const fileRef = this.getFileRef(filename);
    return fileRef.put(file);
  };

  handleStringUpload = string => {
    const test = /data:(.+);base64/i;
    const type = string.match(test)[1];
    const extension = mime.extension(type);
    if (!extension) throw new TypeError('Invalid dataURL');

    const filename = `${shortid.generate()}.${extension}`;
    const fileRef = this.getFileRef(filename);
    return fileRef.putString(string, 'data_url');
  };

  afterUpload = snapshot => {
    const { firebase } = this.props;
    return snapshot.ref.getDownloadURL().then(downloadURL => {
      const metadata = {
        downloadURL,
        fullPath: snapshot.metadata.fullPath,
        name: snapshot.metadata.name,
      };
      return firebase.push(`/${STORAGE_PATH}`, metadata)
        .then(({ key }) => firebase.set(`/${URL_PATH}/${md5(downloadURL)}`, key).then(() => ({
          ...metadata,
          key,
        })));
    });
  };

  upload = file => {
    if (file) {
      if (file instanceof File) {
        return this.handleFileUpload(file).then(this.afterUpload);
      }
      return this.handleStringUpload(file).then(this.afterUpload);
    }
    return Promise.reject();
  }

  deleteFile = fullPath => this.getFileRef(fullPath).delete()

  deleteSnap = (snap) => {
    const { fullPath } = snap.val()
    return Promise.all([
      this.deleteFile(fullPath),
      snap.ref.remove(),
    ])
  }

  deleteByKey = key => this.props.firebase.ref(`/${STORAGE_PATH}/${key}`).once('value').then(this.deleteSnap)

  deleteByUrl = downloadURL => {
    const { firebase } = this.props
    return firebase.ref(`/${URL_PATH}/${md5(downloadURL)}`).once('value').then((snap) => {
      const key = snap.val()
      return firebase.ref(`/${STORAGE_PATH}/${key}`).once('value').then(this.deleteSnap);
    })
  }

  render() {
    const { SubComp, ...props } = this.props;
    const storage = {
      upload: this.upload,
      deleteFile: this.deleteFile,
      deleteByKey: this.deleteByKey,
      deleteByUrl: this.deleteByUrl,
      getSnapshot: this.getSnapshot,
    };
    return <SubComp {...props} storage={storage} />;
  }
}

const WithStorage = withFirebase(BindStorage);

export default SubComp => props => <WithStorage SubComp={SubComp} {...props} />;
