import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';

import Button from './Button'
import Box from './Box'


class Toolbox extends PureComponent {
  handleClickOutside = () => {
    this.props.onRequestClose()
  }

  render() {
    return this.props.children
  }
}

const WithClickToolbox =  onClickOutside(Toolbox);

class ToolboxToggle extends PureComponent {
  state = {

  }

  handleToggle = (e) => {
    e.stopPropagation()
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen
    }))
  }

  handleClose = () => this.setState({ isOpen: false })

  render() {
    const { isOpen } = this.state
    const { toggle, children, title, ...props } = this.props
    return (
      <Box position="relative" {...props}>
        <Button.outline.icon onClick={this.handleToggle} title={title}>
          {toggle}
        </Button.outline.icon>
        {isOpen && (
          <Box position="absolute" top="0" transform="translateY(-100%)" bg="white" p="0.5em" borderRadius="1em">
            <WithClickToolbox
              onRequestClose={this.handleClose}
              // outsideClickIgnoreClass="toolbox-toggle"
            >
              {children}
            </WithClickToolbox>
          </Box>
        )}
      </Box>
    );
  }
}

export default ToolboxToggle
