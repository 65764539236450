import React, { PureComponent, createElement } from 'react';

import Box from 'components/Box'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Table from 'components/Table'

import { selectFirebaseData } from 'services/firebase/selectors'

class TableEditor extends PureComponent {
  state = {}
  handleOpen = () => this.setState({ isOpen: true })
  handleClose = () => this.setState({ isOpen: false })
  handleDragEnd = (widths) => {
    this.widths = widths
  }
  handleConfirm = () => {
    this.setState({ loading: true })
    this.props.firebase.ref(`${this.props.id}/colWidths`, this.widths)
      .then(() => this.setState({ loading: false, isOpen: false }))
  }

  render() {
    const { data, id } = this.props
    const tableConfig = this.props[`tables/${id}`]
    // console.log(data)
    // console.log(tableConfig)
    return (
      <Box my="1em">
        <Button fontSize="0.8em" onClick={this.handleOpen}>編輯欄寬</Button>
        <Modal
          isOpen={this.state.isOpen}
          loading={this.state.loading}
          onRequestClose={this.handleClose}
          onConfirm={this.handleConfirm}
        >
          <Table
            colWidths={tableConfig && tableConfig.colWidths}
            onDragEnd={this.handleDragEnd}
          >
            {data}
          </Table>
        </Modal>
      </Box>
    );
  }
}

export default props => createElement(selectFirebaseData([{
  path: `tables/${props.id}`,
}])(TableEditor), props);
