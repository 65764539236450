import React, { Component } from 'react';
import {
  MdAdd,
  MdChevronLeft,
  MdChevronRight,
  MdDelete,
} from 'react-icons/md'
import size from 'lodash/size'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import sortBy from 'lodash/sortBy'
import pushid from 'pushid'

import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Input from 'components/Input'
import Button from 'components/Button'
import Modal from 'components/Modal';

const transformItems = (items, poses = {}, second, defaultValue = []) => {
  const secondRun = {}
  const toRun = second || items
  let value = Object.keys(toRun).reduce((all, id) => {
    const { parent, text } = items[id]
    if (parent) {
      if (poses[parent]) {
        const theItem = poses[parent].reduce((item, i) => {
          item[i].children = item[i].children || [];
          return item[i].children;
        }, all)

        const pos = theItem.push({ id, text })
        poses[id] = [...poses[parent], pos - 1];
      } else {
        secondRun[id] = items[id]
      }
    } else {
      const pos = all.push({ id, text })
      poses[id] = [pos - 1];
    }
    return all
  }, defaultValue)
  if (size(secondRun)) value = transformItems(items, poses, secondRun, value)
  return value
}

const restoreItems = (value, parent) => value.reduce((items, { id, text, children }) => {
  if (children) {
    const subItems = restoreItems(children, id)
    Object.assign(items, subItems)
  }
  items[id] = { text }
  if (parent) items[id].parent = parent
  return items
}, {})

class Nestable extends Component {
  static defaultProps = {
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
  }

  static getDerivedStateFromProps({ value }, { items }) {
    if (size(items)) return {}
    if (value) {
      return { items: restoreItems(value),  value }
    }
    return {}
  }

  constructor(props) {
    super(props)
    this.handleSync = throttle(this.handleSync, 500).bind(this)
    this.inputRef = React.createRef()
  }

  state = {
    items: {},
    value: [],
  }

  handleAdd = (parent) => this.setState(({ items }) => {
    const id = pushid()
    items[id] = parent ? { parent, text: '' } : { text: '' };
    const value = transformItems(items)
    this.setState({ items, value }, () => {
      this.handleSelect(id)()
      this.handleSync()
    })
  })

  handleSelect = (selected) => () => {
    this.setState({ selected }, () => {
      if (this.inputRef.current) {
        this.inputRef.current.focus()
      }
    })
  }

  handleChange = (e) => {
    const { selected, items } = this.state;
    items[selected].text = e.target.value;
    this.setState({ items, value: transformItems(items) }, this.handleSync)
  }

  handleSync = () => {
    const { value } = this.state
    this.props.onChange({
      target: {
        value,
      }
    })
  }

  onRequestDelete = (toDelete) => () => {
    this.setState({ toDelete })
  }

  onCancelDelete = () => {
    this.setState({ toDelete: null })
  }

  onIncreaseIndent = (id, parent) => () => {
    const { items } = this.state;
    items[id].parent = parent
    this.setState({ items, value: transformItems(items) }, this.handleSync)
  }

  onDecreaseIndent = (id, parent) => () => {
    const { items } = this.state;
    const grandParent = items[parent].parent
    if (grandParent) {
      items[id].parent = grandParent
    } else {
      delete items[id].parent
    }
    this.setState({ items, value: transformItems(items) }, this.handleSync)
  }

  onDelete = (id) => () => {
    const { items } = this.state;
    delete items[id]
    this.setState({ items, value: transformItems(items), toDelete: null }, this.handleSync)
  }

  renderItem = ({ id, children }, i, list) => {
    const { selected, items } = this.state;
    const item = items[id];
    return (
      <Box key={id} my="0.25em">
        <Flex>
          <Button.outline.icon mr="0.25em" disabled={!item.parent} onClick={this.onDecreaseIndent(id, item.parent)}>
            <MdChevronLeft />
          </Button.outline.icon>
          <Button.outline.icon mr="0.25em" disabled={!i} onClick={this.onIncreaseIndent(id, get(list[i - 1], 'id'))}>
            <MdChevronRight />
          </Button.outline.icon>
          <Button.outline.icon mr="0.25em" onClick={() => this.handleAdd(id)}>
            <MdAdd />
          </Button.outline.icon>
          <Button.outline.icon mr="0.25em" onClick={this.onRequestDelete(id)}>
            <MdDelete />
          </Button.outline.icon>
          <Box
            border="1px solid"
            onClick={this.handleSelect(id)}
            borderColor={selected === id ? 'red' : 'black'}
            p="0.25em"
            flex="1"
            height="1.75em"
            overflow="hidden"
          >
            <Text>{item.text}</Text>
          </Box>
        </Flex>
        {children && (
          <Box pl="1em">{sortBy(children, 'id').map(this.renderItem)}</Box>
        )}
      </Box>
    )
  }

  render() {
    const { items, value, selected, toDelete } = this.state
    const { onFocus, onBlur } = this.props;
    return (
      <div>
        <Box mb="2em">
          {value.map(this.renderItem)}
        </Box>
        {selected && items[selected] && (
          <Box my="1em">
            <Input
              key={selected}
              ref={this.inputRef}
              is="textarea"
              onChange={this.handleChange}
              value={items[selected].text}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </Box>
        )}
        <Button.outline onClick={() => this.handleAdd()} leftIcon={MdAdd} disabled={size(items)}>
          Add List
        </Button.outline>
        <Modal
          isOpen={toDelete}
          onConfirm={this.onDelete(toDelete)}
          onRequestClose={this.onCancelDelete}
        >確定刪除？</Modal>
      </div>
    );
  }
}

export default Nestable;
