import { defineMessages } from 'react-intl';

export default defineMessages({
  confirm: {
    id: 'app.components.Modal.confirm',
    defaultMessage: '確認',
  },
  cancel: {
    id: 'app.components.Modal.cancel',
    defaultMessage: '取消',
  },
});
