import React from 'react';
import sortBy from 'lodash/sortBy'

import Box from 'components/Box'
import Text from 'components/Text'

const renderText = ({ text, id }) => <Text.p key={id}>{text}</Text.p>

const renderList = (list) => list && sortBy(list, 'id').map(({ text, children, id }) => ([
  <Box is="ul" key={id}><Box is="li">{text}</Box></Box>,
  children && sortBy(children, 'id').map(renderText),
]))

const Renderer = ({ children }) => {
  return children ? (
    <Box is="ul">
      <Box is="li">
        <Text>{children[0].text}</Text>
        {renderList(children[0].children)}
      </Box>
    </Box>
  ) : null;
};

export default Renderer;
