import React, { createElement, PureComponent } from 'react';
import { isLoaded } from 'react-redux-firebase'

import Table from 'components/Table'
import { selectFirebaseData } from 'services/firebase/selectors'

import LayoutContext from './LayoutContext'

class RemoteTable extends PureComponent {
  handleDragEnd = (colWidths) => {
    this.props.firebase.set(`${this.props.id}/colWidths`, colWidths)
  }

  handleAlignChange = (i, align) => {
    this.props.firebase.set(`${this.props.id}/colAligns/${i}`, align)
  }

  render() {
    const { id, children, ...props } = this.props
    const data = props[id]
    return children && isLoaded(data)
      ? (
        <Table
          onDragEnd={this.handleDragEnd}
          onAlignChange={this.handleAlignChange}
          colWidths={data && data.colWidths}
          colAligns={data && data.colAligns}
          {...props}
        >{children}</Table>) : null
  }
}

export default props => (
  <LayoutContext.Consumer>
    {({ layoutId }) => {
      const id = `tables/${layoutId}/${props.id}`;
      return createElement(selectFirebaseData([id])(RemoteTable), { ...props, id })
    }}
  </LayoutContext.Consumer>
);
