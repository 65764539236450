import React from 'react';

import Text from 'components/Text'
import Flex from 'components/Flex'

import IconSet from './IconSet'

const toggleValue = (value, name) => {
  const pos = value.findIndex((v) => v === name)
  return pos > -1 ? value.splice(pos + 1, 1) : value.concat(name)
}

const IconSetEditor = ({
  icons,
  name,
  label,
  value,
  onChange,
  props,
}) => {
  const iconKeys = Object.keys(icons);
  return (
    <Flex>
      <Text fontWeight="bold" mr="1em">{label}</Text>
      <IconSet
        icons={iconKeys.map((k) => icons[k])}
        flexWrap="wrap"
        {...props}
        orders={iconKeys.map((k) => value.findIndex((v) => v === k))}
        onIconClick={(i) => onChange({
          target: {
            name,
            value: toggleValue(value, iconKeys[i])
          }
        })}
      />
    </Flex>
  );
};

IconSetEditor.defaultProps = {
  value: []
}

export default IconSetEditor;
