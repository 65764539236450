import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import disableScroll from '../../utils/disableScroll';

import Content from './Content';

Modal.setAppElement('#___gatsby');

const style = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
  overlay: {
    zIndex: 9999,
  },
};

class MyModal extends PureComponent {
  componentWillReceiveProps({ isOpen }) {
    if (this.props.isOpen !== isOpen) {
      if (isOpen) {
        disableScroll.on({
          whitelist: [this.modalRef.node],
        });
      } else {
        disableScroll.off();
      }
    }
  }

  componentWillUnmount() {
    if (this.props.isOpen) {
      disableScroll.off();
    }
  }

  handleRef = (ref) => {
    this.modalRef = ref;
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      onAfterOpen,
      contentLabel,
      children,
      onConfirm,
      simple,
      shouldCloseOnOverlayClick,
      shouldCloseOnEsc,
      isLoading,
      ...props
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        onAfterOpen={onAfterOpen}
        contentLabel={contentLabel}
        shouldCloseOnOverlayClick={!isLoading && shouldCloseOnOverlayClick}
        shouldCloseOnEsc={!isLoading && shouldCloseOnEsc}
        style={style}
        ref={this.handleRef}
      >
        {simple ? children : (
          <Content
            {...props}
            onRequestClose={onRequestClose}
            onConfirm={onConfirm}
            isLoading={isLoading}
          >
            {children}
          </Content>
        )}
      </Modal>
    );
  }
}

MyModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onAfterOpen: PropTypes.func,
  contentLabel: PropTypes.string,
  children: PropTypes.node,
  simple: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  isLoading: PropTypes.bool,
};

MyModal.defaultProps = {
  contentLabel: 'Modal',
};

export default MyModal;
