import React, { PureComponent, createElement } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import size from 'lodash/size'
import get from 'lodash/get'
import pickBy from 'lodash/pickBy'
import round from 'lodash/round'
import { compose } from 'redux'
import { isLoaded } from 'react-redux-firebase'
import md5 from 'blueimp-md5'

import Box from 'components/Box'
import Flex from 'components/Flex'
// import ImageBox from 'components/Image'
import Text from 'components/Text'
import Input from 'components/Input'
import Loading from 'components/Loading'
import Button from 'components/Button'

import { selectFirebaseData } from 'services/firebase/selectors'
import withStorage from 'services/firebase/withStorage'

// const isSVg = filename => {
//   const re = /(\w+)(\.\w+)+(?!.*(\w+)(\.\w+)+)/g
//   const res = re.exec(filename)
//   return res && res[2] === '.svg'
// }

// const isBase64 = str => {
//   const regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
//   return regex.test(str);
// };

var getGcd = function(a, b) {
  if ( ! b) {
    return a;
  }
  return getGcd(b, a % b);
};

const getRatio = (crop, image) => {
  if (!image) return ''
  const rw = Math.round((crop.width || 100) * image.width / 100)
  const rh = Math.round((crop.height || 100) * image.height / 100)
  const gcd = getGcd(rw, rh)
  return `${rw / gcd} : ${rh / gcd}`
}

class ImageCropper extends PureComponent {
  static defaultProps = {
    onChange: () => {}
  }

  static getDerivedStateFromProps(nextProps, { cropped }) {
    const key = `${nextProps.hash}Cropping`
    if (!size(cropped) && nextProps[key] && nextProps[key].crop) {
      const { crop } = nextProps[key];
      return { cropped: crop }
    }
    return {}
  }

  state = {
    crop: {},
    cropped: {},
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handelShiftDown)
    window.addEventListener('keyup', this.handelShiftUp)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handelShiftDown)
    window.removeEventListener('keyup', this.handelShiftUp)
  }

  handelShiftDown = (e) => {
    if (e.keyCode === 16) {
      this.fixedRatio = true
    }
  }

  handelShiftUp = (e) => {
    if (e.keyCode === 16) {
      this.fixedRatio = false
    }
  }

  handleImageLoaded = (image) => {
    this.imageRef = image;
    const ratio = getRatio(this.state.cropped, image);
    this.setState({ ratio })
    this.handleRatioChange({ target: { value: ratio } })
  }

  handleUpload = (e) => {
    this.setState({
      loading: true,
    });
    const file = e.target.files[0];

    this.handleFileUpload(file).then((url) => {
      this.handleChange(url)
      this.setState({
        loading: false,
        filename: file.name,
      })
    })
  }

  getImage = (file) => new Promise((res) => {
    const image = new Image();
    image.onload = () => {
      res(image);
    }
    image.src = window.URL.createObjectURL(file);
  })

  getFileData = (file) => new Promise((res) => {
    const reader = new FileReader();
    reader.onload = () => {
      res(reader.result);
    };
    reader.readAsDataURL(file);
  })

  handleCropping = ({ x, y, width, height, unit, aspect }) => {
    const crop = {};
    if (!this.imageRef) return
    if (width && height) {
      // console.log(x, y, width, height, unit)
      if (unit === 'px') {
        crop.x = round(x / this.imageRef.width * 100, 2)
        crop.y = round(y / this.imageRef.height * 100, 2)
        crop.width = round(width / this.imageRef.width * 100, 2)
        crop.height = this.fixedRatio ? this.getOtherSide('width', 'height')(crop) : round(height / this.imageRef.height * 100, 2)
        crop.unit = '%'
      } else {
        crop.x = x
        crop.y = y
        crop.width = width
        crop.height = height
        crop.unit = unit
      }
    }
    if (size(crop)) {
      this.setState({ crop, cropped: null })
    }
  }

  handleCropped = (crop = this.state.crop) => {
    const { id, firebase } = this.props;
    const isValidCrop = size(crop) && !isNaN(crop.height) && !isNaN(crop.width)
    if (isValidCrop) {
      this.setState({ loading: true })
      const ratio = getRatio(crop, this.imageRef)
      this.handleRatioChange({ target: { value: ratio } })
      firebase.set(`/cropping/${id}/crop`, pickBy(crop, (value) => typeof value !== 'undefined'))
        .then(this.setState({ loading: false, cropped: crop, ratio }))
    }
  }

  getOtherSide = (thisSide, otherSide) =>
    (cropData) => (cropData[thisSide] || 100) * this.imageRef[thisSide] * (thisSide === 'width' ? this.currentRatio : 1 / this.currentRatio) / this.imageRef[otherSide]

  handleRatio = () => {
    const { cropped, crop } = this.state;
    if (!this.inputRatio || !this.currentRatio) return
    const cropData = cropped || crop
    const masterRatio = this.imageRef.height / this.imageRef.width
    let sides = ['height', 'width']
    const wSide = this.currentRatio < masterRatio
    if (wSide) sides = Array.from(sides).reverse()
    const otherSide = this.getOtherSide(...sides)(cropData)

    if (cropData && cropData.width) {
      this.handleCropped({
        ...cropData,
        [sides[1]]: otherSide,
      })
    } else {
      this.handleCropped({
        x: 0,
        y: 0,
        [sides[0]]: 100,
        [sides[1]]: otherSide,
        unit: '%',
      })
    }
  }

  handleRatioChange = (e) => {
    this.inputRatio = e.target.value
    const ratio = this.inputRatio.split(':').map((r) => +r)
    if (ratio.length === 2 && ratio.every(Boolean)) {
      this.currentRatio = ratio[1] / ratio[0]
    }
  }

  handleChange = (url) => {
    const { onChange, name } = this.props;
    onChange({
      target: {
        name,
        value: url,
      }
    })
  }

  handleFileUpload = (base64) => {
    const {
      hash,
      firebase,
      id,
      storage: { upload, deleteByKey },
    } = this.props;
    const croppingData = this.props[`${hash}Cropping`]
    return upload(base64).then((file) => {
      const tasks = [firebase.set(`cropping/${id}/file`, file.key)]
      if (croppingData && croppingData.file) {
        tasks.push(deleteByKey(croppingData.file))
      }
      return Promise.all(tasks).then(() => file.downloadURL)
    })
  }

  // getCroppedImg = () => {
  //   const { crop } = this.state;
  //   const canvas = document.createElement('canvas');
  //   const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
  //   const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
  //   canvas.width = crop.width * scaleX;
  //   canvas.height = crop.height * scaleY;
  //   const ctx = canvas.getContext('2d');

  //   ctx.drawImage(
  //     this.imageRef,
  //     crop.x * scaleX,
  //     crop.y * scaleY,
  //     crop.width * scaleX,
  //     crop.height * scaleY,
  //     0,
  //     0,
  //     canvas.width,
  //     canvas.height,
  //   );

  //   // As Base64 string
  //   return canvas.toDataURL('image/jpeg');
  // }

  handleKeyEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      this.handleRatio()
    }
  }

  render() {
    const { label, hash } = this.props;
    const { loading, cropped, crop, filename, ratio }  = this.state
    const croppingData = this.props[`${hash}Cropping`]
    const croppingDataPopulates = this.props[`${hash}CroppingPopulates`]
    if (!isLoaded(croppingData) || !isLoaded(croppingDataPopulates)) return <Loading />
    const src = get(croppingDataPopulates, 'file.downloadURL', filename)
    // console.log(crop, cropped)
    return (
      <div>
        <Flex is="label">
          <Text mr="1em" fontWeight="bold">{label}</Text>
          <Box flex="1">
            <Box
              is="input"
              width="100%"
              type="file"
              accept="image/*"
              onChange={this.handleUpload}
              disabled={loading}
            />
          </Box>
        </Flex>
        {loading && <Loading />}
        {src && (
          <Box>
            <Flex my="1em">
              <Input onChange={this.handleRatioChange} onKeyDown={this.handleKeyEnter} label="更改長寬比" />
              <Button
                ml="0.5em"
                fontSize="0.8em"
                onClick={this.handleRatio}
                disabled={loading}
              >套用</Button>
            </Flex>
            <Box>
              <span style={{ userSelect: 'none' }}>目前長寬比: </span>{ratio}
            </Box>
            <ReactCrop
              src={src}
              crop={size(crop) ? crop : cropped}
              onImageLoaded={this.handleImageLoaded}
              onChange={this.handleCropping}
              onComplete={() => this.handleCropped()}
              disabled={loading}
            />
            {/* <Button disabled={loading} fontSize="0.8em">上傳裁切</Button> */}
          </Box>
        )}
      </div>
    );
  }
}

export default props => {
  const hash = md5(props.id)
  return createElement(
    compose(
      withStorage,
      selectFirebaseData([{
        path: `/cropping/${props.id}`,
        populates: [{ child: 'file', root: 'uploads' }],
        storeAs: `${hash}Cropping`,
      }])
    )(ImageCropper),
    { ...props, hash }
  )
};
