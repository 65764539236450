import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom'
import { tsvParse } from 'd3-dsv'
import Measure from 'react-measure'
import range from 'lodash/range'
import mapValues from 'lodash/mapValues'
import styled from 'styled-components';
import classnames from 'classnames'
// import themeGet from '@styled-system/theme-get'
import {
  MdSettingsEthernet,
  MdFormatAlignCenter,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatAlignJustify,
} from 'react-icons/md'

import Box from './Box'
import Flex from './Flex'
import Text from './Text'
import Circle from './Circle'
import Input from './Input'
import Button from './Button'
import Toolbox from './Toolbox'
import MarkdownParser from './MarkdownParser'

const StyledTable = styled.table`
border-collapse: separate;
border-spacing: 0;
width: 100%;
user-select: none;
`

class Table extends Component {
  static getDerivedStateFromProps({ children, noHeader, colType, attached, numberStart }) {
    // if (prevState.parsed) return {}
    let data = children
    const heaAtt = noHeader || attached
    if (colType === 'number') {
      const lines = children.split('\n')
      data = lines.map((line, i) => i ? `${numberStart - 1 + i}\t${line}` : `項次\t${line}`).join('\n')
    } else if (heaAtt) {
      const [first] = children.split('\n')
      data = `${first.split('\t').map((n, i) => `col${i + 1}`).join('\t')}\n${children}`
    }
    const parsed = tsvParse(data)
    const rowSpans = [heaAtt ? 0 : 1];
    if (colType === 'collapse') {
      let nowVal
      let nowPos = 0
      const key = parsed.columns[0]
      parsed.forEach((row, i) => {
        if (i || heaAtt) {
          if (typeof nowVal === 'undefined') {
            nowVal = row[key]
          }
          if (nowVal !== row[key] && row[key]) {
            nowVal = row[key]
            nowPos = i
            rowSpans[nowPos] = 1
          } else {
            rowSpans[nowPos] += 1
          }
        }
      }, [])
    }
    const attachedHeader = attached && attached.split('\t')
    let attachedSpan = 0;
    if (attachedHeader) {
      attachedHeader.some((h) => {
        if (!h) attachedSpan += 1
        return h
      })
    }
    const lineBreakParsed = parsed.map(row => mapValues(row, v => v ? v.replace(/\|/g, '\n') : v))
    lineBreakParsed.columns = parsed.columns
    return {
      parsed: lineBreakParsed,
      rowSpans,
      attachedHeader: attached && attached.split('\t'),
      attachedSpan,
    }
  }

  static defaultProps = {
    onDragEnd: () => {},
    onAlignChange: () => {},
    numberStart: 1,
  }

  state = {
    colWidths: this.props.colWidths || [],
    colAligns: this.props.colAligns || [],
  }

  componentDidMount() {
    if (this.cells.length) {
      this.cellBounds = this.cells.map(this.getBound)
      this.cellLines = range(this.cells.length - 1).map((i) => {
        const line = document.createElement('div')
        line.className = 'grid-no-drag no-print'
        line.style.position = 'absolute'
        line.style.width = '10px'
        line.style.background = 'rgba(0,0,0,0.05)'
        line.style.top = 0
        line.style.bottom = 0

        this.linePoses[i] = this.cellBounds[i].right - this.wrapperBounds.left - 5;
        line.style.left = `${this.linePoses[i]}px`
        line.style.cursor = 'col-resize'
        line.addEventListener('mousedown', this.handleMouseDown(i))
        this.handleMouseMove[i] = this.createHandleMouseMove(i)
        this.handleMouseUp[i] = this.createHandleMouseUp(i)
        this.wrapper.appendChild(line)
        return line
      })
    }

    // if (!this.state.colWidths.length) {
      const colWidths = this.cellBounds.map(b => Math.round(b.width))
      this.setState({ colWidths })
    // }
  }

  handleResize = ({ bounds }) => {
    this.wrapperBounds = bounds
  }

  handleMouseDown = i => () => {
    document.addEventListener('mousemove', this.handleMouseMove[i])
    document.addEventListener('mouseup', this.handleMouseUp[i])
  }

  handleMouseUp = []

  createHandleMouseUp = i => () => {
    document.removeEventListener('mousemove', this.handleMouseMove[i])
    document.removeEventListener('mouseup', this.handleMouseUp[i])
    this.props.onDragEnd(this.state.colWidths)
  }

  handleMouseMove = []

  createHandleMouseMove = i => e => {
    this.updateCellWidth(i, e.movementX)
  }

  updateCellWidth = (i, diff, sync) => {
    const { colWidths } = this.state
    if (this.linePoses[i]) {
      colWidths[i + 1] -= diff
      this.linePoses[i] += diff
      this.cellLines[i].style.left = `${this.linePoses[i]}px`
    } else {
      const j = i - 1
      colWidths[j] -= diff
      this.linePoses[j] -= diff
      this.cellLines[j].style.left = `${this.linePoses[j]}px`
    }
    colWidths[i] += diff

    this.setState({ colWidths })
    if (sync) this.props.onDragEnd(colWidths)
  }

  handleAlign = (i, align) => e => {
    const { colAligns } = this.state
    e.stopPropagation()
    colAligns[i] = align
    this.setState({ colAligns }, () => {
      this.props.onAlignChange(i, align)
    })
  }

  getBound = (cell) => cell.getBoundingClientRect()

  updateCellBounds = () => {
    this.cellBounds = this.cells.map(this.getBound)
  }

  widthInput = createRef()

  cells = []
  cellBounds = []
  linePoses = []

  render() {
    const {
      parsed,
      colWidths,
      colAligns,
      rowSpans,
      attachedHeader,
      attachedSpan,
    } = this.state;
    const { noHeader, noBold, colType, customPadding, blockId } = this.props
    if (!parsed.length) return null;
    // console.log(parsed, attachedHeader, attachedSpan)
    // console.log(colWidths)
    // console.log(customPadding)
    const portalDom = document.getElementById(blockId) || this.wrapper
    return (
      <Measure bounds onResize={this.handleResize}>
        {({ measureRef }) => (
          <Box
            position="relative"
            ref={(ref) => {
              this.wrapper = ref;
              measureRef(ref)
            }}
          >
            <StyledTable>
              {!noHeader && (
                attachedHeader ? (
                  <thead>
                    <tr>
                      <th colSpan={attachedSpan} className="attached-blank" />
                      {attachedHeader.map((h, i) => i >= attachedSpan && (
                        <th
                          key={i}
                          className="attached-header"
                          style={{ textAlign: colAligns[i], width: colWidths[i] }}
                        >{h}</th>
                      ))}
                    </tr>
                  </thead>
                ) : (
                  <thead>
                    <tr>
                      {parsed.columns.map((h, i) => (
                        <th style={{ width: colWidths[i], textAlign: colAligns[i] }} key={h}>
                          {h}
                        </th>
                      ))}
                    </tr>
                  </thead>
                )
              )}
              <tbody>
                {parsed.map((row, i) => (
                  <tr key={i}>
                    {parsed.columns.map((key, j) => {
                      if (colType === 'collapse' && !j && typeof rowSpans[i] === 'undefined') return null
                      return (
                        <Box
                          key={key}
                          is="td"
                          position="relative"
                          ref={(ref) => {
                            if (ref && !i) {
                              this.cells[j] = ref
                            }
                          }}
                          className={classnames(
                            colType === 'collapse' && (!j && rowSpans[i] ? 'col-collapse' : 'not-collapse'),
                            attachedSpan && !i && 'under-attached',
                            !j && i === rowSpans.length - 1 && 'is-last-span',
                          )}
                          style={{
                            textAlign: colAligns[j],
                            width: noHeader && !i && colWidths[j],
                            paddingTop: customPadding&& `${customPadding}mm`,
                            paddingBottom: customPadding && `${customPadding}mm`,
                          }}
                          // whiteSpace="pre-wrap"
                          rowSpan={colType === 'collapse' && !j && rowSpans[i]}
                        >
                          {j ? (
                            <Box fontWeight={j === 1 && !noBold && 700}>
                              <MarkdownParser>{row[key]}</MarkdownParser>
                            </Box>
                          ) : (() => {
                            if (colType === 'number') {
                              return (
                                <Circle
                                  width="1.5em"
                                  border="1pt solid"
                                  borderColor="currentColor"
                                  fontWeight="700"
                                  whiteSpace="nowrap"
                                  mx="auto"
                                >{row[key]}</Circle>
                              )
                            }
                            if (colType === 'date') {
                              return (
                                <div>
                                  <Circle
                                    className={`date-circle ${i ? '' : 'is-first'} ${i === parsed.length - 1 ? 'is-last' : ''}`}
                                    width="0.8em"
                                    bg="currentColor"
                                    display="inline-block"
                                    mr="0.5em"
                                  />
                                  <Text.inline verticalAlign="center" fontWeight="bold">
                                    {row[key]}
                                  </Text.inline>
                                </div>
                              )
                            }
                            return row[key]
                          })()}
                        </Box>
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            </StyledTable>
            {portalDom && ReactDOM.createPortal((
              <Flex position="absolute" className="no-print" top="0" left="0" right="0" transform="translateY(-100%)">
                {parsed.columns.map((_, i) => {
                  const width = colWidths[i];
                  return (
                    <Flex key={i} style={{ width }} flex={!width && '1 1 auto'} py="0.25em">
                      {width && (
                        <Toolbox toggle={<MdSettingsEthernet />} title={width}>
                          <Flex>
                            <Input
                              ref={this.widthInput}
                              autoFocus
                              width="4em"
                              type="number"
                              initialValue={width}
                              onKeyUp={(e) => {
                                if (e.keyCode === 13) {
                                  this.updateCellWidth(i, this.widthInput.current.value - width, true)
                                }
                              }}
                            />
                            {/* <Button.outline.icon
                              mx="0.25em"
                              onClick={() => this.updateCellWidth(i, this.widthInput.current.value - width)}
                            ><MdCheck /></Button.outline.icon> */}
                          </Flex>
                        </Toolbox>
                      )}
                      <Toolbox mx="0.25em" toggle={<MdFormatAlignCenter />} title={colAligns[i]}>
                        <Flex mx="-0.25em">
                          <Button.outline.icon
                            mx="0.25em"
                            onClick={this.handleAlign(i, 'left')}
                          ><MdFormatAlignLeft /></Button.outline.icon>
                          <Button.outline.icon
                            mx="0.25em"
                            onClick={this.handleAlign(i, 'center')}
                          ><MdFormatAlignCenter /></Button.outline.icon>
                          <Button.outline.icon
                            mx="0.25em"
                            onClick={this.handleAlign(i, 'right')}
                          ><MdFormatAlignRight /></Button.outline.icon>
                          <Button.outline.icon
                            mx="0.25em"
                            onClick={this.handleAlign(i, 'justify')}
                          ><MdFormatAlignJustify /></Button.outline.icon>
                        </Flex>
                      </Toolbox>
                    </Flex>
                  )
                })}
              </Flex>
            ), portalDom)}
          </Box>
        )}
      </Measure>
    );
  }
}

export default Table;
