import React, { Component, createElement } from 'react';
import { withFirebase } from 'react-redux-firebase'
import toPairs from 'lodash/toPairs'

import Modal from 'components/Modal'
import Box from 'components/Box'
import Input from 'components/Input'
import Text from 'components/Text'
import Dropdown from 'components/Dropdown'

import LayoutContext from './LayoutContext'
import getEditor from './getEditor'


class PageEditor extends Component {
  static contextType = LayoutContext

  state = {}

  requestDelete = () => {
    this.setState({ deleteRequested: true })
  }

  cancelDelete = () => {
    this.setState({ deleteRequested: false })
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.context.onContentChange(`${this.props.pageId}/${name}`)(value);
  }

  renderEditor = ({ name, label, editor }) => {
    const id = `${this.props.pageId}/${name}`
    return createElement(getEditor(editor), {
      key: name,
      id: `${this.props.name}/${id}`,
      name,
      label,
      onChange: this.handleChange,
      value: this.context.contents[id],
      my: '1em',
    })
  }

  render() {
    const { pageId, templates } = this.props;
    const { deleteRequested } = this.state;
    const { contents, onDeletePage } = this.context;
    const template = contents[`${pageId}/template`];
    return (
      <Box>
        <Box mb="2em">
          <Dropdown
            label="更換頁面版型"
            name="template"
            value={template}
            onChange={this.handleChange}
            my="1em"
            pb="0.5em"
            borderBottom="1px solid"
          >
            <option value="">一般</option>
            {toPairs(templates).map(([key, { name }]) => (
              <option key={key} value={key}>{name}</option>
            ))}
          </Dropdown>
          <Input
            label="背景色"
            name="bg"
            onChange={this.handleChange}
            value={contents[`${pageId}/bg`]}
            my="1em"
          />
          {template && templates[template].modules.map(this.renderEditor)}
        </Box>

        <Text
          fontSize="0.8em"
          color="danger"
          onClick={this.requestDelete}
        >Delete Page</Text>
        <Modal
          isOpen={deleteRequested}
          onConfirm={() => onDeletePage(pageId)}
          onRequestClose={this.cancelDelete}
          className="ignore-react-onclickoutside"
        >
          確定刪除？
        </Modal>
      </Box>
    )
  }
}

export default withFirebase(props => <PageEditor {...props} />);
