import get from 'lodash/get'

import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Circle from 'components/Circle'
import BackgroundImage from 'components/BackgroundImage'
import Table from './RemoteTable'

import IconSet from './IconSet'
import Nestable from '../Nestable/Renderer'
import CroppedViewer from '../ImageCropper/Viewer'

const elements = {
  Box,
  Flex,
  Circle,
  BackgroundImage,
  Image: CroppedViewer,
  Table,
  Text,
  IconSet,
  Nestable,
}

export default (ele, fallback) => get(elements, ele, fallback || Box)
