import React from 'react';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

import Box from 'components/Box'

const MdEditor = ({ value, onChange, disabled, onFocus, onBlur }) => (
  <Box position="relative">
    <SimpleMDE
      value={value}
      onChange={disabled ? () => {} : onChange}
      options={{
        indentWithTabs: false,
        spellChecker: false,
        toolbar: [
          'heading-3',
          '|',
          'bold',
          'italic',
          'heading',
          '|',
          'unordered-list',
          'ordered-list',
          'quote',
          '|',
          'horizontal-rule',
        ]
      }}
      events={{
        blur: onBlur,
        focus: onFocus,
      }}
    />
    {disabled && (
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="rgba(255,255,255,0.5)"
        zIndex={1}
      />
    )}
  </Box>
);

MdEditor.defaultProps = {
  onBlur: () => {},
  onFocus: () => {},
}

export default MdEditor
