import React from 'react';

import Box from './Box'

export default (props) => (
  <Box
    position="absolute"
    top="0"
    left="0"
    right="0"
    bottom="0"
    {...props}
  />
)
