import React from 'react';

import Box from './Box'

const Checkbox = ({ label, onBlur, onChange, value, ...props }) => (
  <Box {...props}>
    <label>
      <input
        type="checkbox"
        onChange={e => onChange({ target: { name: e.target.name, value: e.target.checked } })}
        onBlur={onBlur}
        checked={value}
        {...props}
      />
      {label}
    </label>
  </Box>
)

export default Checkbox;
