import React, { PureComponent } from 'react';
import get from 'lodash/get'
import map from 'lodash/map';
import { withContentRect } from 'react-measure';
import { withTheme } from 'styled-components'
import { compose } from 'redux'

import Box from 'components/Box'

const getPoint = (bounds, isEnd) => {
  const x = bounds.left + (isEnd ? 0 : bounds.width);
  const y = bounds.top + bounds.height / 2;
  return [x, y]
}

class ConnectionOverlay extends PureComponent {
  componentDidMount() {
    this.props.measure()
  }

  render() {
    const {
      elementBounds,
      pages,
      measureRef,
      contentRect: { bounds },
      theme,
    } = this.props;
    return (
      <Box
        position="absolute"
        left="0"
        right="0"
        top={`-${theme.headerHeight}`}
        height="100%"
        className="no-print"
        ref={measureRef}
        pointerEvents="none"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width={bounds.width} height={bounds.height}>
          {map(elementBounds, (bound, id) => {
            const eleData = get(pages, id.split('/'));
            if (eleData && eleData.continueTo && get(pages, eleData.continueTo.split('/'))) {
              const start = getPoint(bound)
              const end = getPoint(elementBounds[eleData.continueTo], true)
              return (
                <line
                  key={id}
                  x1={start[0]}
                  y1={start[1]}
                  x2={end[0]}
                  y2={end[1]}
                  stroke="black"
                />
              )
            }
            return null;
          })}
        </svg>
      </Box>
    );
  }
}

export default compose(
  withTheme,
  withContentRect('bounds')
)(ConnectionOverlay);
