import React from 'react'
import get from 'lodash/get'

import Input from 'components/Input'
import Checkbox from 'components/Checkbox'
import Dropdown from 'components/Dropdown'
import Image from '../ImageCropper'
import Nestable from '../Nestable/Editor'
// import Quill from './QuillEditor'
import Markdown from './MdEditor'
import IconSetEditor from './IconSetEditor'

const editors = {
  Textarea: (props) => <Input {...props} is="textarea" rows="8" />,
  Input,
  Checkbox,
  // Quill,
  Markdown,
  Nestable,
  Image,
  Dropdown,
  IconSetEditor,
}

export default (key) => get(editors, key, Input)
