import React, { createElement, forwardRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { textAlign } from 'styled-system';
import themeGet from '@styled-system/theme-get';

import Flex from './Flex';
import Box from './Box';
import Text from './Text';

const TextInput = styled(Box)`
  font-family: inherit;
  width: 100%;
  padding: 0.25em;
  border: 1px solid ${themeGet('colors.gray')};
  line-height: 1;
  border-radius: 0;
  ${textAlign}
  &::placeholder {
    color: ${themeGet('colors.gray')};
  }
`;

class Input extends PureComponent {
  state = { value: this.props.initialValue }

  render() {
    const {
      label,
      labelWidth,
      name,
      placeholder,
      disabled,
      value,
      onChange,
      onBlur,
      error,
      type,
      min,
      max,
      textAlign,
      step,
      rows,
      is,
      forwardedRef,
      autoFocus,
      ...props
    } = this.props
    return (
      <Flex is="label" alignItems="center" {...props}>
        {label && (
          <Box mr="1em" width={labelWidth}>
            <Text fontWeight="bold">{label}</Text>
          </Box>
        )}
        <Box flex="1">
          {createElement(TextInput, {
            name,
            placeholder,
            disabled,
            value: value || this.state.value,
            onChange: e => {
              if (onChange) {
                onChange(e)
              }
              this.setState({ value: e.target.value })
            },
            onBlur,
            type,
            min,
            max,
            textAlign,
            step,
            is,
            rows,
            autoFocus,
            ref: forwardedRef,
          })}
          {error && (
            <Text fontSize="0.8em" align="left" color="red" mt="0.25rem">{error}</Text>
          )}
        </Box>
      </Flex>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string,
  labelWidth: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  is: 'input',
}

Input.displayName = 'Input';

export default forwardRef((props, ref) => <Input {...props} forwardedRef={ref} />)
