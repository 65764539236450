import React, { Component } from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import reduce from 'lodash/reduce';
import { withFirebase } from 'react-redux-firebase'
import toPx from 'to-px'
import { compose } from 'redux'
import onClickOutside from 'react-onclickoutside';

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import Button from 'components/Button'
import Box from 'components/Box'
import Text from 'components/Text'
import Flex from 'components/Flex'
import FullAbs from 'components/FullAbs'

import LayoutGrid from './LayoutGrid';
import TemplatePage from './TemplatePage';

const Page = styled.div.attrs({ className: 'paper' })`
${({ page, config: { width, height, margin, startPage, pagination }, theme }) => {
  const pageNum = page + startPage;
  const isOdd = pageNum % 2;
  const type = isOdd ? 'odd' : 'even';
  const pagiDir = isOdd ? 'right' : 'left';
  return `
    .page-container {
      height: 100%;
      ${['top', 'left', 'right', 'bottom'].reduce((c, d) => `${c}padding-${d}:${margin[type][d] || 15}mm;`, '')}
    }
    .pagination {
      display: block;
      position: absolute;
      top: ${pagination[type].y}mm;
      ${pagiDir}: ${pagination[type].x}mm;
    }
    @page {
      size: ${width || 210}mm ${height || 297}mm;
    }
  `;
}}
`

const segments = 200;
const defaulH = 10;

class Paper extends Component {
  static defaultProps = {
    page: 0
  }

  static getDerivedStateFromProps({ pageId, page, config, name, data }) {
    const pageNum = page + config.startPage;
    const type = pageNum % 2 ? 'odd' : 'even';
    const contentWidth = config.width - config.margin[type].left - config.margin[type].right;
    const contentHeight = config.height - config.margin[type].top - config.margin[type].bottom;
    const columns = config.columns[type];
    const perWidth = (contentWidth - (columns.gutter * (columns.count - 1))) / columns.count;
    let newElementStart = 0;
    if (data) {
      const xHeights = reduce(data.layout, (heights, { grid }) => {
        if (grid) {
          range(grid.w).forEach((n) => {
            heights[grid.x + n] += grid.h;
          })
        }
        return heights;
      }, Array(columns.count).fill(0))
      newElementStart = xHeights.findIndex((h) => (h <= (segments - Math.ceil(defaulH / 2)) / 2))
    }
    return {
      pageNum,
      type,
      contentYStart: `${config.margin[type].top}mm`,
      contentWidth,
      contentHeight,
      columns,
      pageRef: `pages/${name}/${pageId}`,
      columnPoses: range(columns.count).map((col) => config.margin[type].left + (col * (columns.gutter + perWidth))),
      newElementStart,
    }
  }

  state = {
    pageNum: 0,
    type: 'odd',
    mm: toPx('1mm'),
    newElementStart: 0
  }

  shouldComponentUpdate({ data, active, bg, page }, { rowLoading }) {
    return active !== this.props.active
      || bg !== this.props.bg
      || page !== this.props.page
      || !isEqual(data, this.props.data)
      || rowLoading !== this.state.rowLoading
  }

  handleClickOutside = (e) => {
    const { active, onSelectPage } = this.props;
    if (active) {
      onSelectPage(null)(e)
    }
  }

  hanldePageClick = (e) => {
    const { onSelectPage, pageId, active } = this.props
    e.stopPropagation()
    if (!active) onSelectPage(pageId)(e);
  }

  handleAddRow = () => {
    const { firebase, data, onSelectElement, pageId } = this.props;
    const { newElementStart } = this.state;
    const y = data ? data.yMax : 0
    this.setState({ rowLoading: true });
    firebase.push(`${this.state.pageRef}/layout`, {
      grid: {
        w: Math.floor(this.state.columns.count / 2),
        h: defaulH,
        x: newElementStart,
        y,
      }
    }).then(({ key }) => {
      this.setState({ rowLoading: false }, () => onSelectElement(`${pageId}/layout/${key}`))
    })
  }

  render() {
    const {
      layout,
      name,
      data,
      selectedElement,
      active,
      onSelectPage,
      onSelectElement,
      templates,
      modules,
      icons,
      showGrid,
      pageId,
      activeTab,
      tabs,
      bg,
      ...props
    } = this.props;
    const {
      rowLoading,
      pageNum,
      contentWidth,
      contentHeight,
      mm,
      columns,
      columnPoses,
      pageRef,
      type,
      contentYStart,
      newElementStart,
    } = this.state;
    const template = data && templates[data.template];
    const pageData = data && toPairs(data.layout);
    const yMax = data && data.yMax;

    const rowHeight = contentHeight * mm / (segments + 1);
    return (
      <Page
        {...props}
        style={{ background: bg }}
        onClick={this.hanldePageClick}
      >
        {template && template.fullpage && (
          <TemplatePage
            pageId={this.props.pageId}
            template={template}
            columnPoses={columnPoses}
          />
        )}
        <div className="page-container">
          <Box position="relative" height="100%">
            {showGrid && (
              <FullAbs is={Flex} className="no-print">
                {range(columns.count).reduce((grid, i) => {
                  if (i) {
                    grid.push(<Box
                      key={`gutter${i}`}
                      width={`${columns.gutter}mm`}
                    />)
                  }
                  grid.push(
                    <Box
                      key={`box${i}`}
                      border="1px solid"
                      borderColor="primary"
                      flex={1}
                      height="100%"
                    />
                  )
                  return grid;
                }, [])}
              </FullAbs>
            )}
            <FullAbs className="no-print" border="1px dashed" borderColor="danger" />
            {(!template || !template.fullpage) && (
              <Box position="relative">
                <Box
                  position="absolute"
                  left={`-${columns.gutter}mm`}
                  right={`-${columns.gutter}mm`}
                  top={-rowHeight}
                  className="page-content"
                >
                  <LayoutGrid
                    pageId={pageId}
                    cols={columns.count}
                    width={(contentWidth + columns.gutter * 2) * mm}
                    rowHeight={rowHeight}
                    margin={[columns.gutter * mm, rowHeight]}
                    data={pageData}
                    yMax={yMax}
                    pageRef={pageRef}
                    segments={segments}
                  />
                </Box>
                <Box
                  className="no-print"
                  position="absolute"
                  left="0"
                  right="0"
                  top={yMax * 2 * rowHeight}
                >
                  <Button.outline
                    width={1}
                    disabled={rowLoading || newElementStart === -1 || newElementStart > columns.count / 2}
                    onClick={this.handleAddRow}
                  >
                    新增元件
                  </Button.outline>
                </Box>
              </Box>
            )}
          </Box>
        </div>
        {(!template || !template.fullpage) && props.config.tabs && props.config.tabs[type] && (
          <Box
            position="absolute"
            top={contentYStart}
            className="no-print"
            {...props.config.tabs.position}
          >
            {props.config.tabs.chapters.map((tab, i) => (
              <Box
                key={tab + i}
                {...props.config.tabs.props}
                {...(activeTab[props.page] === tab ? props.config.tabs.activeProps : {})}
              >
                <Box width="1em">{tab}</Box>
              </Box>
            ))}
          </Box>
        )}
        <Box className="pagination no-print" color="primary">
          <Text textStyle="pagination">
            {pageNum}
          </Text>
        </Box>
        {active && (
          <FullAbs
            className="no-print"
            border="2px dashed"
            borderColor="red"
            pointerEvents="none"
          />
        )}
      </Page>
    )
  }
}

export default compose(withFirebase, onClickOutside)(Paper);
