import React, { createElement, Component } from 'react';
import ReactDOM from 'react-dom'
import { compose } from 'redux'
import styled, { ThemeProvider } from 'styled-components';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import toPairs from 'lodash/toPairs';
import get from 'lodash/get';
import size from 'lodash/size';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import { isLoaded } from 'react-redux-firebase'
import { TinyColor } from '@ctrl/tinycolor';
import Measure from 'react-measure'
import { MdSave, MdPrint } from 'react-icons/md';
// import { Element, Link, scroller } from 'react-scroll'
import Helmet from 'react-helmet'

import theme from 'components/ThemeProvider/theme';
import FullpageLoading from 'components/FullpageLoading'
import Flex from 'components/Flex'
import Box from 'components/Box'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Refreshing from 'components/Refreshing'
import { selectFirebaseData } from 'services/firebase/selectors';
import withStorage from 'services/firebase/withStorage';
import LanguageContext from 'i18n/LanguageContext'

import Paper from './Paper'
import PageStyler from './PageStyler'
import PageEditor from './PageEditor'
import ElementEditor from './ElementEditor'
import LayoutContext from './LayoutContext'
import ConnectionOverlay from './ConnectionOverlay'
import OverflowCalculator from './OverflowCalculator'
import PageSwitcher from './PageSwitcher';

const PageWrapper = styled(Box)`
  @media print {
    width: 100%;
    padding: 0 !important;
  }
`;

const whitelist = [
  'grid',
  'yMax',
]

const isDataUrl = str => {
  const regex = /^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  return regex.test(str);
};

const pagesPerPart = 10;

class LayoutPage extends Component {
  static getDerivedStateFromProps({ pages, pageOrders, layout }, { contents, chapterColors, tabsIndex }) {
    if (layout) {
      if (!tabsIndex) {
        tabsIndex = layout.config.tabs.chapters.reduce((ti, title, i) => {
          ti[title] = i
          return ti
        }, {})
      }
      if (!chapterColors) {
        chapterColors = layout.config.tabs.colors.map((color) => ({
          ...color,
          primaryHover: new TinyColor(color.primary).darken(10).toHexString(),
        }))
      }
    }
    const { activeTab, tabs, colors } = (pages && pageOrders) ? pageOrders.reduce((result, pageId, i) => {
      if (pages[pageId].template === 'coverPage') {
        let { title } = pages[pageId];
        title = title ? title.replace(/\n|\r|/g, '') : ''
        result.tabs.push(title)
        result.activeTab.push(title)
        result.colors.push(chapterColors[tabsIndex[title]])
      } else {
        result.activeTab.push(i ? result.activeTab[i - 1] : '')
        result.colors.push(i ? chapterColors[tabsIndex[result.activeTab[i - 1]]] : {})
      }
      return result;
    }, {
      activeTab: [],
      tabs: [],
      colors: [],
    }) : {
      activeTab: [],
      tabs: [],
      colors: [],
    };
    // console.log(colors)
    return {
      contents: pages ? reduce(pages, (c, data, pageId) => {
        const flatternValue = (obj, namespace) => {
          forEach(obj, (v, k) => {
            if (!whitelist.includes(k)) {
              const p = `${namespace}/${k}`;
              if (isObject(v) && !isArray(v)) {
                flatternValue(v, p)
              } else {
                const content = contents[p]
                if (typeof content === 'undefined') c[p] = v
              }
            }
          })
        }
        flatternValue(data, pageId)
        return c
      }, contents) : {},
      theme: layout ? merge({}, theme, {
        colors: layout.config.colors,
        list: layout.config.list,
        textStyles: layout.config.typography.textStyles,
      }) : {},
      activeTab,
      tabs,
      totalParts: pageOrders ? Math.ceil(pageOrders.length / pagesPerPart) : 0,
      colors,
      chapterColors,
    }
  }

  static contextType = LanguageContext

  static defaultProps = {
    partNum: '1',
  }

  state = {
    creatingPage: false,
    contents: {},
    elementBounds: {},
    theme: {},
    activeTab: [],
    tabs: [],
    colors: [],
    bounds: {},
  }

  componentDidMount() {
    this.autoSave = setInterval(this.handleSave, 60 * 1000);
    window.addEventListener('beforeunload', this.beforeClose);
  }

  componentDidUpdate(prevProps) {
    const { pages } = this.props;
    if (isArray(pages) && isArray(prevProps.pages) && pages.length !== prevProps.pages.length) {
      this.setState({ selectedPage: null });
    }
    if (isLoaded(pages) && !pages && !this.firstAdded) {
      this.addFirstPage();
    }
    // if (isLoaded(pages) && isLoaded(pageOrders) && !this.scrolled) {
    //   this.scrollToElement(pageNum)
    // }
    // if (pageNum !== prevProps.pageNum) {
    //   this.scrollToElement(pageNum)
    // }
  }

  componentWillUnmount() {
    this.handleSave()
    clearInterval(this.autoSave);
    window.removeEventListener('beforeunload', this.beforeClose);
    // console.log('layout um')
  }

  beforeClose = (event) => {
    event.preventDefault();
    event.returnValue = '';
    return window.confirm()
  }

  // scrollToElement = (pageNum) => {
  //   this.scrolled = true;
  //   scroller.scrollTo(`page${pageNum}`);
  // }

  addFirstPage = () => {
    this.firstAdded = true;
    this.handleAddPage();
  }

  openConfig = () => this.setState({ configIsOpen: true })
  closeConfig = () => this.setState({ configIsOpen: false })

  handleSave = () => {
    const { firebase, layoutId } = this.props;
    if (this.state.saving) return Promise.resolve()
    this.setState({ saving: true });
    return Promise.all(toPairs(this.state.contents)
      .map(([path, content]) => {
        return firebase.ref(`pages/${layoutId}/${path}`).transaction((data) => {
          if (isDataUrl(content)) return data
          if (typeof content === 'boolean') {
            return content || null;
          }
          if (!isNil(data) && data === content) {
            return data;
          }
          // console.log(path, 'autosave')
          return content;
        })
      })
    ).catch(() => {
      return alert('儲存失效，請檢查網頁連線')
    }).then(() => {
      this.setState({ saving: false });
    })
  }

  handleAddPage = (after) => {
    const { layoutId, firebase } = this.props;
    this.setState({ creatingPage: true });
    return firebase.push(`pages/${layoutId}`, { yMax: 0 })
      .then(({ key }) => firebase.ref(`pageOrders/${layoutId}`).transaction((orders) => {
        if (orders) {
          orders.splice(after + 1, 0, key)
          return orders
        }
        return [key];
      }))
      .then(() => {
        this.setState({ creatingPage: false });
      });
  }

  handleSelectElement = (selectedElement) => {
    this.setState({ selectedElement, selectedPage: null })
  }

  handleSelectPage = (selectedPage) => () => {
    this.setState({ selectedElement: null, selectedPage })
  }

  handleDeletePage = (pageId) => {
    const { layoutId, firebase } = this.props
    return firebase
      .ref(`pageOrders/${layoutId}`).transaction((orders) => {
        const index = orders.findIndex((id) => id === pageId);
        orders.splice(index, 1)
        return orders
      })
      .then(() => firebase.remove(`pages/${layoutId}/${pageId}`))
      .then(() => this.setState(({ contents }) => ({
        contents: pickBy(contents, (v, key) => !key.startsWith(pageId)),
        selectedPage: null,
      })))
  }

  handleDeleteElement = (ele) => () => {
    const { layoutId, firebase } = this.props

    return firebase.remove(`pages/${layoutId}/${ele}`).then(() => {
      this.setState(({ contents }) => ({
        selectedElement: null,
        contents: pickBy(contents, (v, key) => !key.startsWith(ele)),
      }))
    });
  }

  handlePasteElement = (from, to) => {
    if (from === to) return Promise.resolve()
    const { layoutId, firebase } = this.props
    const { contents } = this.state
    const fromData = pickBy(contents, (v, key) => key.startsWith(from))
    if (size(fromData)) {
      const tasks = [
        firebase.ref(`pages/${layoutId}/${from}`).transaction(({ grid }) => ({ grid })),
        ...map(fromData, (v, k) => firebase.set(`pages/${layoutId}/${k.replace(from, to)}`, v)),
      ]
      if (fromData[`${from}/module`] === 'image') {
        tasks.push(
          firebase.ref(`cropping/${layoutId}/${from}/children/img`).once('value')
            .then((snap) => Promise.all([
              firebase.set(`cropping/${layoutId}/${to}/children/img`, snap.val()),
              snap.ref.remove(),
            ]))
        )
      }
      if (fromData[`${from}/module`] === 'table') {
        tasks.push(
          firebase.ref(`tables/${layoutId}/${from}/children/data`).once('value')
            .then((snap) => Promise.all([
              firebase.set(`tables/${layoutId}/${to}/children/data`, snap.val()),
              snap.ref.remove(),
            ]))
        )
      }
      Object.keys(fromData).forEach((key) => {
        contents[key.replace(from, to)] = contents[key]
        delete contents[key]
      })
      this.setState({ contents })
      return Promise.all(tasks)
    }
    return Promise.resolve()
  }

  handleContentChange = (ele) => (value) => {
    this.setState(({ contents }) => {
      contents[ele] = value
      return { contents }
    })
  }

  handleElementBound = (ele) => (bound) => {
    this.setState(({ elementBounds }) => {
      elementBounds[ele] = bound
      return { elementBounds }
    })
  }

  enterConnectionMode = () => {
    this.setState({
      connecting: true,
    });
  }

  exitConnectionMode = () => {
    this.setState({
      connecting: false,
    });
  }

  togglePreview = () => {
    this.setState(({ preview }) => ({ preview: !preview }))
  }

  setBaseLineHeight = (baseLineHeight) => {
    this.setState({ baseLineHeight })
  }

  // hanldePageActive = (pageNum) => () => {
  //   window.history.pushState(null, null, `/${this.context.locale}/layouts/${this.props.layoutId}/page/${pageNum + 1}`)
  // }

  handleConnected = (targetElement) => {
    const { selectedElement } = this.state;
    const { firebase, layoutId } = this.props
    this.exitConnectionMode()
    if (targetElement !== selectedElement) {
      return Promise.all([
        firebase.set(`pages/${layoutId}/${selectedElement}/continueTo`, targetElement),
        firebase.set(`pages/${layoutId}/${targetElement}/isContinued`, true),
      ]).then(() => {
        this.setState(({ contents }) => {
          contents[`${selectedElement}/continueTo`] = targetElement;
          contents[`${targetElement}/isContinued`] = true;
        });
      })
    }
  }

  handleCancelConnect = targetElement => {
    const { firebase, layoutId } = this.props
    const { contents } = this.state
    const continueTo = contents[`${targetElement}/continueTo`];
    return Promise.all([
      firebase.remove(`pages/${layoutId}/${targetElement}/continueTo`),
      firebase.remove(`pages/${layoutId}/${continueTo}/isContinued`),
    ]).then(() => {
      delete contents[`${targetElement}/continueTo`]
      delete contents[`${continueTo}/isContinued`]
      this.setState({ contents });
    })
  }

  handleElementOverflow = (element, diff) => {
    const { pages } = this.props
    const { elementBounds, theme, contents, baseLineHeight } = this.state;
    const elementData = get(pages, element.split('/'));
    const { continueTo } = elementData;
    if (continueTo && get(pages, continueTo.split('/'))) {
      const boundBox = elementBounds[element];
      const original = contents[`${element}/children`];
      const continued = contents[`${continueTo}/children`] || '';
      const fullString = original + continued;
      if (!this.pageRef) {
        this.pageRef = document.querySelector('.page-container');
      }
      const virtualDom = document.createElement('div')
      virtualDom.style.opacity = 0;
      virtualDom.style.visibility = 'none';
      this.pageRef.appendChild(virtualDom)
      this.setState({ calculating: true })

      ReactDOM.render((
        <ThemeProvider theme={theme}>
          <Box position="absolute" width={boundBox.width}>
            <OverflowCalculator
              direction={Math.sign(diff)}
              targetHeight={boundBox.height}
              onCalculated={(stringEnds) => {
                contents[`${element}/children`] = fullString.substr(0, stringEnds)
                contents[`${continueTo}/children`] = fullString.substr(stringEnds);
                this.setState({ contents, calculating: false });
                virtualDom.remove()
              }}
              original={original}
              fullString={fullString}
              baseLineHeight={baseLineHeight}
            />
          </Box>
        </ThemeProvider>
      ), virtualDom)
    }
  }

  handlePrint = () => {
    window.print ()
  }

  handleMeasure = ({ bounds }) => {
    this.setState({ bounds })
  }

  render() {
    const { layout, pages, layoutId, pageOrders, partNum } = this.props;
    const {
      creatingPage,
      selectedPage,
      selectedElement,
      contents,
      connecting,
      elementBounds,
      calculating,
      baseLineHeight,
      configIsOpen,
      activeTab,
      tabs,
      colors,
      bounds,
      saving,
      totalParts,
    } = this.state;
    if (!isLoaded(layout) || !isLoaded(pages) || !isLoaded(pageOrders)) return <FullpageLoading />
    // console.log(colors, tabs, activeTab)
    return (
      <LayoutContext.Provider
        value={{
          onElementSelected: this.handleSelectElement,
          onContentChange: this.handleContentChange,
          onDeleteElement: this.handleDeleteElement,
          onDeletePage: this.handleDeletePage,
          onConnectStart: this.enterConnectionMode,
          onConnectStop: this.exitConnectionMode,
          onConnected: this.handleConnected,
          onCancelConnect: this.handleCancelConnect,
          onElementOverflow: this.handleElementOverflow,
          onPasteElement: this.handlePasteElement,
          setElementBound: this.handleElementBound,
          setBaseLineHeight: this.setBaseLineHeight,
          elementBounds,
          selectedElement,
          contents,
          layout,
          connecting,
          baseLineHeight,
          layoutId,
        }}
      >
        <Helmet>
          <title>{layoutId}-part-{partNum}</title>
        </Helmet>
        <Flex
          position="fixed"
          top="18px"
          right="1em"
          zOrder={3}
          className="no-print"
        >
          <Button.icon color="white" onClick={this.handleSave} disabled={saving}>
            {saving ? <Refreshing size={28} /> : <MdSave size={28} />}
          </Button.icon>
          <Button.icon ml="1em" color="white" onClick={this.handlePrint}>
            <MdPrint size={28} />
          </Button.icon>
        </Flex>
        <PageSwitcher
          className="no-print"
          top={theme.headerHeight}
          px="1em"
          py="0.5em"
          bg="white"
          borderBottom="1px solid"
          borderColor="gray"
          zOrder={2}
          partNum={partNum}
          totalParts={totalParts}
          totalPage={pageOrders.length}
          layoutId={layoutId}
          locale={this.context.locale}
        />
        <Flex position="relative">
          <Measure bounds onResize={this.handleMeasure}>
            {({ measureRef }) => (
              <Box className="no-print" flex="1" px="1em" position="relative">
                <div ref={measureRef} />
                <Box
                  position="fixed"
                  width={bounds.width}
                  top="90px"
                  py="1cm"
                  height="calc(100% - 90px)"
                  overflow="auto"
                  className="ignore-react-onclickoutside"
                >
                  {/* <Button.outline
                    fontSize="0.8em"
                    onClick={this.openConfig}
                    mb="1em"
                  >全域設定</Button.outline> */}
                  {selectedPage && (
                    <PageEditor
                      name={layoutId}
                      templates={layout.templates}
                      pageId={selectedPage}
                      pageData={pages && pages[selectedPage]}
                    />
                  )}
                  {selectedElement && (
                    <ElementEditor
                      key={selectedElement}
                      layoutId={layoutId}
                      modules={layout.modules}
                      icons={layout.icons}
                      selectedElement={selectedElement}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Measure>
          <PageWrapper px="1cm" pt="2em">
            {pages && pageOrders && pageOrders.map((pageId, i) => (
              <ThemeProvider theme={Object.assign({}, this.state.theme, { colors: colors[i] })} key={pageId}>
                <PageStyler config={layout.config} {...layout.config.typography.default}>
                  <div name={`page${i + 1}`}>
                    {/* <Box position="absolute" pointerEvents="none" opacity="0">
                      <Link to={`page${i + 1}`} spy={true} onSetActive={this.hanldePageActive(i)} />
                    </Box> */}
                    <Paper
                      {...layout}
                      page={i}
                      pageId={pageId}
                      data={pages[pageId]}
                      selectedElement={selectedElement}
                      active={selectedPage === pageId}
                      onSelectElement={this.handleSelectElement}
                      onSelectPage={this.handleSelectPage}
                      bg={contents[`${pageId}/bg`]}
                      activeTab={activeTab}
                      tabs={tabs}
                    />
                    <Box className="no-print" my="1cm" textAlign="center">
                      <Button
                        disabled={creatingPage}
                        onClick={() => this.handleAddPage(i)}
                      >Add Page</Button>
                    </Box>
                  </div>
                </PageStyler>
              </ThemeProvider>
            )).slice((partNum - 1) * pagesPerPart, partNum * pagesPerPart)}
          </PageWrapper>
          {Boolean(size(elementBounds)) && (
            <ConnectionOverlay
              elementBounds={elementBounds}
              pages={pages}
            />
          )}
        </Flex>
        {calculating && <FullpageLoading />}
        <Modal
          isOpen={configIsOpen}
          onRequestClose={this.closeConfig}
          onConfirm={this.closeConfig}
        >
          Configs
        </Modal>
      </LayoutContext.Provider>
    );
  }
}

export default (props) => createElement(
  compose(
    selectFirebaseData([
      {
        path: `layouts/${props.layoutId}`,
        storeAs: 'layout',
      }, {
        path: `pages/${props.layoutId}`,
        storeAs: 'pages',
      }, {
        path: `pageOrders/${props.layoutId}`,
        storeAs: 'pageOrders',
      }
    ]),
    withStorage
  )(LayoutPage), props);
