import { createElement, Component } from 'react';

import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'

import Flex from 'components/Flex'
import getElement from './getElement'

const renderItems = (fields, icons, blockId) => layouts => layouts.map((layout, i) => {
  let child = null;
  const { children } = layout
  if (isArray(children)) {
    child = renderItems(fields, icons, blockId)(children)
  } else if (isString(layout)) {
    const field = fields[layout]
    let { value } = field;
    if (field.element === 'IconSet' && value) {
      value = value.map((ic) => icons[ic])
    }
    const { mapChildToProp, inlcudeProps } = field
    child = createElement(
      getElement(field.element),
      Object.assign(
        { id: field.id, key: field.id, blockId },
        field.props,
        mapChildToProp ? { [mapChildToProp]: value } : {},
        inlcudeProps ? inlcudeProps.reduce((ip, key) => {
          ip[key] = fields[key].value
          return ip
        }, {}) : {}
      ),
      mapChildToProp ? null : value
    );
  }
  return createElement(getElement(layout.element), { ...layout.props, key: i }, child);
})

class Module extends Component {
  shouldComponentUpdate(nextProps) {
    return ['config', 'values', 'icons'].some((key) => !isEqual(nextProps[key], this.props[key]))
  }

  render() {
    const { config, values, icons, blockId } = this.props;
    const fields = config.fields.reduce((fs, field) => {
      fs[field.name] = {
        value: values[field.name],
        id: `${blockId}/children/${field.name}`,
        ...field
      };
      return fs
    }, {})
    return createElement(
      getElement(config.element, Flex),
      config.props,
      renderItems(fields, icons, blockId)(config.layout)
    )
  }
}

export default Module;
