import React, { Component, createRef } from 'react';

import MarkdownParser from 'components/MarkdownParser'

class OverflowCalculator extends Component {
  state = {
    stringEnds: this.props.original.length,
  }

  componentDidMount() {
    setTimeout(this.iterate)
  }

  shouldComponentUpdate(nextProps, { stringEnds }) {
    return this.state.stringEnds !== stringEnds;
  }

  componentDidUpdate() {
    const { targetHeight, direction, onCalculated } = this.props;
    if (this.container) {
      if (direction * (this.container.current.getBoundingClientRect().height - targetHeight) > 0) {
        onCalculated(this.state.stringEnds - direction)
      } else {
        setTimeout(this.iterate)
      }
    }
  }

  container = createRef()

  iterate = () => {
    const { stringEnds } = this.state;
    const { fullString, direction } = this.props;
    if (stringEnds > 0 && stringEnds <= fullString.length) {
      this.setState({ stringEnds: stringEnds + direction })
    } else {
      this.props.onCalculated(stringEnds)
    }
  }

  // handleResize = (contentRect) => {
  //   const { targetHeight, direction } = this.props;
  //   if (direction * (contentRect.bounds.height - targetHeight) > 0) {
  //     this.handleStopped()
  //   }
  // }

  // handleStopped = () => {
  //   this.stopped = true;
  //   this.props.onCalculated(this.state.stringEnds)
  // }

  render() {
    const { stringEnds } = this.state
    const { fullString } = this.props
    return (
      <div ref={this.container}>
        <MarkdownParser>{fullString.substr(0, stringEnds)}</MarkdownParser>
      </div>
    )
  }
}

export default OverflowCalculator;
