import React from 'react';
import PropTypes from 'prop-types'

import Box from './Box'
import Flex from './Flex'
import Text from './Text'

const Dropdown = ({
  label,
  children,
  value,
  onBlur,
  onChange,
  name,
  options,
  ...props
}) => {
  return (
    <Flex is="label" {...props}>
      <Text fontWeight="bold" mr="1em">{label}</Text>
      <Box
        is="select"
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      >
        {options ? options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        )) : children}
      </Box>
    </Flex>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

export default Dropdown;
