import React, { PureComponent, createRef } from 'react';

const extractViewBox = svg => {
  const res = /viewBox="([^"]+)/.exec(svg);
  return res ? res[1] : '';
}
const extractSvgContent = svg => {
  const res = /<svg[^>]+>([\S\s]*?)<\/svg>/.exec(svg)
  return res ? res[1] : '';
}

class InlineSVG extends PureComponent {
  dom = createRef()

  componentDidMount() {
    this.dom.current.innerHTML = extractSvgContent(this.props.children)
  }

  render() {
    const { children, ...props } = this.props;
    return <svg ref={this.dom} viewBox={extractViewBox(children)} {...props} />
  }
}

export default InlineSVG;
