import React, { PureComponent } from 'react';
import GridLayout from 'react-grid-layout';
import throttle from 'lodash/throttle';
import pickBy from 'lodash/pickBy';
import { withFirebase } from 'react-redux-firebase'

import GridBox from './GridBox'

class LayoutGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.handleLayoutChange = throttle(this.handleLayoutChange, 500).bind(this)
  }

  state = {}

  handleLayoutChange = layout => {
    const { firebase, pageRef } = this.props;
    const yMax = layout.reduce((ym, { h, y }) => {
      const newyM = h + y;
      return newyM > ym ? newyM : ym;
    }, 0);
    Promise.all(layout.map(({ i, ...grid }) => {
      return firebase.update(`${pageRef}/layout/${i}/grid`, pickBy(grid, v => typeof v !== 'undefined'));
    })).then(() => firebase.set(`${pageRef}/yMax`, yMax));
  }

  handleStartResize = () => {
    this.setState({ resizing: true })
  }

  handleStopResize = () => {
    this.setState({ resizing: false })
  }

  // handleMatchHeight = (targetHeight) => {
  //   // console.log(targetHeight)
  // }

  render() {
    const {
      cols,
      width,
      rowHeight,
      margin,
      data,
      pageId,
    } = this.props;
    const { resizing } = this.state
    return (
      <GridLayout
        className="layout"
        cols={cols}
        width={width}
        rowHeight={rowHeight}
        margin={margin}
        draggableCancel=".grid-no-drag"
        onLayoutChange={this.handleLayoutChange}
        onResizeStart={this.handleStartResize}
        onResizeStop={this.handleStopResize}
      >
        {data && data.map(([key, { grid }]) => {
          const id = `${pageId}/layout/${key}`;
          return (
            <div key={key} data-grid={grid}>
              <GridBox
                id={id}
                grid={grid}
                resizing={resizing}
              />
            </div>
          );
        })}
      </GridLayout>
    );
  }
}

export default withFirebase(LayoutGrid);
