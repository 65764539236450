import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const animations = {
  rotation,
};

export default (animation) => (SubComp) => styled(({
  animating,
  duration,
  delay,
  iteration,
  timing,
  ...props
}) => (
  <SubComp
    {...props}
  />
))`
  animation-name: ${({ animating }) => animating && animations[animation]};
  animation-duration: ${({ duration }) => duration || 1000}ms;
  animation-delay: ${({ delay }) => delay || 0}ms;
  animation-iteration-count: ${({ iteration }) => iteration || 'infinite'};
  animation-timing-function: ${({ timing }) => timing || 'ease'};
`;
