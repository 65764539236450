import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl'

import { MdClose } from 'react-icons/md';

import Button from '../Button';
import Box from '../Box';
import Flex from '../Flex';

import messages from './messages';

const Content = ({
  onRequestClose,
  children,
  onConfirm,
  isLoading,
  ...props
}) => (
  <Box p="0.5em" textAlign="right" position="relative" {...props}>
    <Box
      ml="auto"
      disabled={isLoading}
      onClick={onRequestClose}
    >
      <MdClose />
    </Box>
    <Box px="2em">
      <Box py="1em" textAlign="center">
        {children}
      </Box>
      <Flex my="1em" justify="center">
        <Button.outline disabled={isLoading} mx="0.5em" onClick={onRequestClose}><FormattedMessage {...messages.cancel} /></Button.outline>
        <Button disabled={isLoading} mx="0.5em" onClick={onConfirm}><FormattedMessage {...messages.confirm} /></Button>
      </Flex>
    </Box>
  </Box>
);

Content.propTypes = {
  onRequestClose: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default Content;
