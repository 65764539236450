import React, { PureComponent , createElement} from 'react';
import toPairs from 'lodash/toPairs'
import isEqual from 'lodash/isEqual'

import Box from 'components/Box'
import Text from 'components/Text'
import Input from 'components/Input'
import Dropdown from 'components/Dropdown'
import Modal from 'components/Modal';

import MdEditor from './MdEditor'
import TableEditor from './TableEditor'
import LayoutContext from './LayoutContext'
import getEditor from './getEditor'

class ElementEditor extends PureComponent {
  static contextType = LayoutContext

  state = {}

  componentDidMount() {
    document.addEventListener('keyup', this.handleDeleteKey)
  }

  // shouldComponentUpdate({ selectedElement, context, modules, selectedModule }) {
  //   if (!isEqual(modules, this.props.modules)) return true
  //   return
  // }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleDeleteKey)
  }

  handleDeleteKey = (e) => {
    if (e.keyCode === 46 && !this.isEditing) {
      this.requestDelete()
    }
  }

  handleFocus = () => {
    this.isEditing = true
  }

  handleBlur = () => {
    this.isEditing = false
  }

  requestDelete = () => {
    this.setState({ confirmDelete: true })
  }

  cancleDelete = () => {
    this.setState({ confirmDelete: false })
  }

  requestPaste = (e) => {
    this.setState({ confirmPaste: true, pasteSource: e.target.value })
  }

  canclePaste = () => {
    this.setState({ confirmPaste: false })
  }

  handlePaste = () => {
    this.setState({ isLoading: true })
    this.context.onPasteElement(this.state.pasteSource, this.props.selectedElement)
      .then(() => this.setState({ isLoading: false, confirmPaste: false }))
  }

  render() {
    const { selectedElement, modules, icons, layoutId } = this.props;
    const { contents, onContentChange, onDeleteElement, onCancelConnect } = this.context
    const { confirmDelete, confirmPaste, isLoading } = this.state;

    const selectedModule = contents[`${selectedElement}/module`];
    const isContinued = contents[`${selectedElement}/isContinued`];
    const continueTo = contents[`${selectedElement}/continueTo`];

    return (
      <div>
        {!isContinued && (
          <Dropdown
            label="模組類型"
            name="module"
            onChange={(e) => onContentChange(`${selectedElement}/module`)(e.target.value)}
            value={selectedModule}
            mb="2em"
          >
            <option value="">一般</option>
            {toPairs(modules).map(([value, { label }]) => <option key={value} value={value}>{label}</option>)}
          </Dropdown>
        )}
        {selectedModule ? (
          <Box>
            {modules[selectedModule].fields.map(({ name, editor, label, props, editorProps }) => {
              const id = `${selectedElement}/children/${name}`;
              return createElement(getEditor(editor), {
                key: id,
                id: `${layoutId}/${id}`,
                name,
                label,
                onChange: (e) => onContentChange(id)(e.target.value),
                onFocus: this.handleFocus,
                onBlur: this.handleBlur,
                value: contents[id],
                my: '1em',
                props,
                icons,
                ...editorProps,
              })
            })}
          </Box>
        ): (
          <MdEditor
            key={selectedElement}
            value={contents[`${selectedElement}/children`] || ''}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={onContentChange(`${selectedElement}/children`)}
          />
        )}
        {/* {selectedModule === 'table' && (
          <TableEditor
            id={`${layoutId}/${selectedElement}/children/data`}
            data={contents[`${selectedElement}/children/data`]}
          />
        )} */}
        <Input my="1em" label="ID" value={selectedElement} readOnly onFocus={(e) => e.target.select()} />
        <Input label="貼上ID" onChange={this.requestPaste} />
        {continueTo && (
          <Text
            fontSize="0.8em"
            fontWeight="bold"
            color="primary"
            mt="2em"
            onClick={() => onCancelConnect(selectedElement)}
          >取消連結</Text>
        )}
        <Box mt="1em">
          <Text
            is="span"
            fontSize="0.8em"
            fontWeight="bold"
            color="danger"
            onClick={this.requestDelete}
          >Delete</Text>
        </Box>
        <Modal
          isOpen={confirmDelete}
          onConfirm={onDeleteElement(selectedElement)}
          onRequestClose={this.cancleDelete}
        >確定刪除？</Modal>
        <Modal
          isOpen={confirmPaste}
          onConfirm={this.handlePaste}
          onRequestClose={this.canclePaste}
          isLoading={isLoading}
        >確定貼上並取代？</Modal>
      </div>
    );
  }
}

export default ElementEditor
