import styled from 'styled-components';
import toPairs from 'lodash/toPairs';
import { typography } from 'styled-system'

import Box from 'components/Box'
import styledToCss from 'components/utils/styledToCss'

const pseudo = ['before', 'after']

const nestedStyle = (config, theme) => {
  const { children } = config;
  const childStyles = children ? toPairs(children).map(([key, childConfig]) => {
    return childConfig ? ` ${decodeURIComponent(key)}{
      ${nestedStyle(childConfig, theme)}
    }` : ''
  }).join('') : '';
  let pseudoStyles = ''
  let mainStyles = ''
  if (config.props) {
    pseudoStyles = pseudo.reduce((s, key) => {
      const psuedoConfig = config.props[key];
      if (psuedoConfig) {
        const { content, ...rest } = psuedoConfig;
        return `${s}&::${key}{
          content: ${content || '""'};
          ${styledToCss(rest, theme)}
        }`
      }
      return s;
    }, '');
    mainStyles = styledToCss(config.props, theme)
  }

  return mainStyles + childStyles + pseudoStyles;
}

export default styled(Box).attrs({ id: 'page-theme' })`
.paper {
  ${typography}
  /* Divide single pages with some space and center all pages horizontally */
  margin: 1cm auto;
  /* Define a white paper background that sticks out from the darker overall background */
  ${'' /* background: #fff;
   */}
  /* Show a drop shadow beneath each page */
  box-shadow: 0 4px 5px rgba(75, 75, 75, 0.2);
  /* Override outline from user agent stylesheets */
  outline: 0;

  position: relative;
  ${({ config: { width, height, styles }, theme }) => {
    return `
      width: ${width || 210}mm;
      height: ${height || 297}mm;
      ${styles && toPairs(styles).reduce((s, [key, style]) => `${s}${decodeURIComponent(key)}{${nestedStyle(style, theme)}}`, '')}
    `;
  }}
}

/* Defines a class for manual page breaks via inserted .page-break element */
div.page-break {
  page-break-after: always;
}
/* For top-level headings only */
h1 {
  /* Force page breaks after */
  page-break-before: always;
}
/* For all headings */
h1, h2, h3, h4, h5, h6 {
  /* Avoid page breaks immediately */
  page-break-after: avoid;
}
/* For all paragraph tags */
p {
  /* Reset the margin so that the text starts and ends at the expected marks */
  margin: 0;
}
/* For links in the document */
a {
  /* Prevent colorization or decoration */
  text-decoration: none;
  color: black;
}
/* For tables in the document */
table {
  /* Avoid page breaks inside */
  page-break-inside: avoid;
}

img {
  width: 100%;
  pointer-events: none;
}

.react-resizable-handle {
  @media print {
    display: none !important;
  }
}
`
