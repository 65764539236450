import React, { Component } from 'react';
import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'
import { MdLink } from 'react-icons/md';
import Measure from 'react-measure'

import Box from 'components/Box';
import MarkdownParser from 'components/MarkdownParser';
import Button from 'components/Button';
import FullAbs from 'components/FullAbs'

import Module from './Module'
import LayoutContext from './LayoutContext'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  ${(props) => props.connecting && `
    background: ${themeGet('colors.variations.gray.3')(props)};
    &:hover {
      background: ${themeGet('colors.primary')(props)};
    }
  `}
  @media print {
    background: transparent !important;
  }
`;

class GridBox extends Component {
  static contextType = LayoutContext

  constructor(props, context) {
    super(props, context);
    this.handleResize = throttle(this.handleResize, 500).bind(this)
  }

  state = {}

  componentDidMount() {
    this.updateContainerBound()
    setTimeout(this.setBaseLineHeight)
  }

  componentDidUpdate({ grid, id }) {
    const contentModule = this.context.contents[`${id}/module`];
    if (!contentModule && !isEqual(this.props.grid, grid)) {
      this.updateContainerBound()
      this.handleResize(this.contentRect)
    }
  }

  setBaseLineHeight = () => {
    const { baseLineHeight, setBaseLineHeight } = this.context
    if (!baseLineHeight && this.container) {
      const p = document.createElement('p')
      p.innerText = '字'
      p.style.opacity = 0;
      p.style.visibility = 'none';
      this.container.appendChild(p)
      setBaseLineHeight(p.getBoundingClientRect().height)
      p.remove()
    }
  }

  updateContainerBound = () => {
    this.containerBound = this.container.getBoundingClientRect()
    this.containerBound.x += window.scrollX
    this.containerBound.y += window.scrollY
    this.context.setElementBound(this.props.id)(this.containerBound)
  }

  handleContainer = (ref) => {
    this.container = ref;
  }

  handleResize = contentRect => {
    const { resizing, id } = this.props
    this.contentRect = contentRect;
    if (!resizing && this.containerBound) {
      const diff = this.containerBound.height - contentRect.bounds.height
      if (Math.abs(diff) > this.context.baseLineHeight) {
        this.context.onElementOverflow(id, diff);
      }
    }
  }

  handleClick = (e) => {
    const { id } = this.props;
    const {
      onElementSelected,
      onConnected,
      onConnectStop,
      connecting,
      contents,
    } = this.context;
    const contentModule = contents[`${id}/module`];
    e.stopPropagation()
    if (connecting) {
      if (contentModule) {
        onConnectStop()
      } else {
        onConnected(id);
      }
    } else {
      onElementSelected(id);
    }
  }

  render() {
    const {
      id,
      resizing,
      onMatchHeight,
      ...props
    } = this.props;
    const {
      selectedElement,
      onConnectStart,
      connecting,
      contents,
      layout: { modules, icons },
    } = this.context;
    const contentModule = contents[`${id}/module`];
    const active = id === selectedElement;
    return (
      <FullAbs id={id} {...props}>
        <Wrapper
          onClick={this.handleClick}
          active={active}
          connecting={!contentModule && connecting}
          ref={this.handleContainer}
        >
          {contentModule ? (
            <Module
              blockId={id}
              icons={icons}
              config={modules[contentModule]}
              values={modules[contentModule].fields.reduce((vals, { name }) => {
                vals[name] = contents[`${id}/children/${name}`];
                return vals;
              }, {})}
            />
          ) : (
            <Measure bounds onResize={this.handleResize}>
              {({ measureRef }) => (
                <div ref={measureRef}>
                  <MarkdownParser>{contents[`${id}/children`] || ''}</MarkdownParser>
                </div>
              )}
            </Measure>
          )}
          {!contentModule && active && (
            <Box position="absolute" top="0" right="0" className="no-print">
              <Button
                px="0.5em"
                py="0.25em"
                onClick={onConnectStart}
              >
                <MdLink />
              </Button>
            </Box>
          )}
          {active && (
            <FullAbs
              className="no-print"
              pointerEvents="none"
              border="2px dashed"
              borderColor="red"
            />
          )}
        </Wrapper>
      </FullAbs>
    );
  }
}

export default GridBox;
