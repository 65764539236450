import React, { createElement, Component } from 'react';
import ReactDOM from 'react-dom'
import { compose } from 'redux'
import styled, { ThemeProvider } from 'styled-components';
import forEach from 'lodash/forEach';
import noop from 'lodash/noop'
import map from 'lodash/map';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import toPairs from 'lodash/toPairs';
import get from 'lodash/get';
import size from 'lodash/size';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import { isLoaded } from 'react-redux-firebase'
import { TinyColor } from '@ctrl/tinycolor';
import Measure from 'react-measure'
// import { Element, Link, scroller } from 'react-scroll'
import Helmet from 'react-helmet'

import theme from 'components/ThemeProvider/theme';
import FullpageLoading from 'components/FullpageLoading'
import Flex from 'components/Flex'
import Box from 'components/Box'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Refreshing from 'components/Refreshing'
import { selectFirebaseData } from 'services/firebase/selectors';
import withStorage from 'services/firebase/withStorage';
import LanguageContext from 'i18n/LanguageContext'

import Paper from './Paper'
import PageStyler from './PageStyler'
import PageEditor from './PageEditor'
import ElementEditor from './ElementEditor'
import LayoutContext from './LayoutContext'
import ConnectionOverlay from './ConnectionOverlay'
import OverflowCalculator from './OverflowCalculator'
import PageSwitcher from './PageSwitcher';

const PageWrapper = styled(Box)`
  @media print {
    width: 100%;
    padding: 0 !important;
  }
`;

const whitelist = [
  'grid',
  'yMax',
]

const isDataUrl = str => {
  const regex = /^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  return regex.test(str);
};

const pagesPerPart = 10;

class LayoutPage extends Component {
  static getDerivedStateFromProps({ pages, pageOrders, layout }, { contents }) {
    const { activeTab, tabs } = (pages && pageOrders) ? pageOrders.reduce((result, pageId, i) => {
      if (pages[pageId].template === 'coverPage') {
        let { title } = pages[pageId];
        title = title ? title.replace(/\n|\r|/g, '') : ''
        result.tabs.push(title)
        result.activeTab.push(title)
      } else {
        result.activeTab.push(i ? result.activeTab[i - 1] : '')
      }
      return result;
    }, {
      activeTab: [],
      tabs: [],
    }) : {
      activeTab: [],
      tabs: [],
    };
    return {
      contents: pages ? reduce(pages, (c, data, pageId) => {
        const flatternValue = (obj, namespace) => {
          forEach(obj, (v, k) => {
            if (!whitelist.includes(k)) {
              const p = `${namespace}/${k}`;
              if (isObject(v) && !isArray(v)) {
                flatternValue(v, p)
              } else {
                const content = contents[p]
                if (typeof content === 'undefined') c[p] = v
              }
            }
          })
        }
        flatternValue(data, pageId)
        return c
      }, contents) : {},
      theme: layout ? merge({}, theme, {
        colors: {
          ...layout.config.colors,
          primaryHover: new TinyColor(layout.config.colors.primary).darken(10).toHexString(),
        },
        // colors: layout.config.colors,
        list: layout.config.list,
        textStyles: layout.config.typography.textStyles,
      }) : {},
      activeTab,
      tabs,
    }
  }

  static contextType = LanguageContext


  state = {
    creatingPage: false,
    contents: {},
    elementBounds: {},
    theme: {},
    activeTab: [],
    tabs: [],
    bounds: {},
  }

  handleMeasure = ({ bounds }) => {
    this.setState({ bounds })
  }

  handleElementBound = (ele) => (bound) => {
    this.setState(({ elementBounds }) => {
      elementBounds[ele] = bound
      return { elementBounds }
    })
  }

  setBaseLineHeight = (baseLineHeight) => {
    this.setState({ baseLineHeight })
  }

  render() {
    const { layout, pages, layoutId, pageOrders, partNum } = this.props;
    const {
      creatingPage,
      selectedPage,
      selectedElement,
      contents,
      connecting,
      elementBounds,
      calculating,
      baseLineHeight,
      configIsOpen,
      activeTab,
      tabs,
      bounds,
      saving,
      totalParts,
    } = this.state;
    if (!isLoaded(layout) || !isLoaded(pages) || !isLoaded(pageOrders)) return <FullpageLoading />
    return (
      <ThemeProvider theme={this.state.theme}>
        <LayoutContext.Provider
          value={{
            onElementSelected: noop,
            onContentChange: noop,
            onDeleteElement: noop,
            onDeletePage: noop,
            onConnectStart: noop,
            onConnectStop: noop,
            onConnected: noop,
            onCancelConnect: noop,
            onElementOverflow: noop,
            onPasteElement: noop,
            setElementBound: this.handleElementBound,
            setBaseLineHeight: this.setBaseLineHeight,
            elementBounds,
            selectedElement,
            contents,
            layout,
            connecting,
            baseLineHeight,
            layoutId,
          }}
        >
          <Helmet>
            <title>{layoutId}</title>
          </Helmet>
          <PageWrapper mx="auto" px="1cm" pt="2em">
            <PageStyler config={layout.config} {...layout.config.typography.default}>
              <div>
                {pages && pageOrders && pageOrders.map((pageId, i) => (
                  <div key={pageId} name={`page${i + 1}`}>
                    <Paper
                      {...layout}
                      page={i}
                      pageId={pageId}
                      data={pages[pageId]}
                      onSelectElement={() => {}}
                      onSelectPage={() => {}}
                      bg={contents[`${pageId}/bg`]}
                      activeTab={activeTab}
                      tabs={tabs}
                    />
                  </div>
                ))}
              </div>
            </PageStyler>
          </PageWrapper>
        </LayoutContext.Provider>
      </ThemeProvider>
    );
  }
}

export default (props) => createElement(
  compose(
    selectFirebaseData([
      {
        path: `layouts/${props.layoutId}`,
        storeAs: 'layout',
      }, {
        path: `pages/${props.layoutId}`,
        storeAs: 'pages',
      }, {
        path: `pageOrders/${props.layoutId}`,
        storeAs: 'pageOrders',
      }
    ]),
    withStorage
  )(LayoutPage), props);
