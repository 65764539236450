import React from 'react';

import Flex from 'components/Flex'
import Box from 'components/Box'
import InlineSVG from 'components/InlineSVG'

const IconSet = ({ icons, size, gutter, onIconClick, orders, ...props }) => {
  if (!icons) return null;
  return (
    <Flex {...props}>
      {icons.map((icon, i) => (
        <Box
          position="relative"
          ml={i ? gutter : null}
          width={size}
          key={i}
          onClick={onIconClick ? () => onIconClick(i) : null}
        >
          <InlineSVG>{icon}</InlineSVG>
          {orders && orders[i] > -1 && (
            <Box
              position="absolute"
              fontSize="0.8em"
              fontWeight="bold"
              top="0"
              right="0"
              bg="primary"
              color="white"
              p="0.5em"
              transform="translate(25%, -25%)"
            >
              {orders[i] + 1}
            </Box>
          )}
        </Box>
      ))}
    </Flex>
  );
};

export default IconSet;
