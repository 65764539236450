import React, { createElement } from 'react';
import ReactMarkdown from 'react-markdown';

import Box from './Box'
import Text from './Text'

const renderers = {
  paragraph: Text.p,
  link: 'span',
  heading: ({ level, children }) => createElement(Text[`h${level}`], null, children),
  list: ({ ordered, children }) => <Box is={ordered ? 'ol' : 'ul'}>{children}</Box>,
  listItem: ({ children }) => <Box is="li">{children}</Box>,
}

const MarkdownParser = ({ children }) => {
  return (
    <ReactMarkdown source={children} renderers={renderers} />
  );
};

export default MarkdownParser;
